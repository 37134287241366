// Move app's entrypoint into separate chunk to avoid undefined config
export const loadConfig = async () => {
  if (!process.env.CONFIG_PATH) {
    throw new Error('CONFIG_PATH is not defined');
  }
  const runtimeConfigData = await fetch(process.env.CONFIG_PATH);
  window.runtimeConfig = await runtimeConfigData.json();
};
const start = async () => {
  await loadConfig();
  return import('../../cloudplatform-login');
};
start();
export {};